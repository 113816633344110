* {
    margin: 0;
    padding: 0;
}

html,
body {
    overflow: hidden;
    font-family: Arial;
}

.container {
    display: grid;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    grid-template-columns: 300px auto;
    grid-template-rows: auto 200px;
}

    .materials {
        position: relative;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        box-shadow: 4px 0px 6px 0px rgba(0, 0, 0, 0.1);
        padding: 30px;
    }

    .materials__group {
        padding-bottom: 20px;
    }

        .materials__group__thumbs {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-gap: 5px;
            padding: 20px 0px;
        }

            .materials__thumb {
                position: relative;
                padding-top: 100%;
                cursor: pointer;
            }

                .materials__thumb img {
                    top: 0px;
                    left: 0px;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                }


    .canvas__container {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        position: relative;
    }

        canvas.webgl {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            outline: none;
        }

    .thumbs {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        position: relative;
        display: flex;
        padding: 10px;
        height: 200px;
        box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.1);
        background-color: #f4f4f4;
        box-sizing: border-box;
    }

        .thumb {
            flex: 1;
            height: 100%;
            overflow: hidden;
        }

            .thumb img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
